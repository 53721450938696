<template>
  <div class="header">
    <v-row
      class=""
      justify="space-between"
      align="center"
    >
      <v-col
        md="auto"
        lg="2"
        cols="12"
      >
        <h4
          class="text-h4"
          style="color: var(--v-primary-base);font-weight:bold"
        >
          {{ titleText }}
        </h4>
      </v-col>
      <v-col
        md="auto"
        lg="10"
        cols="12"
      >
        <div class="d-flex align-center flex-lg-nowrap flex-wrap justify-end">
          <div
            id="filter_status"
            class="filter_status"
          >
            <v-btn
              depressed
              class="v-btn_tab"
              :class="{ 'active': activeFilter === 'bot_resolve' }"
              active-class="active"
              @click="sendFilter('bot')"
            >
              <div class="d-flex flex-column align-center">
                <!-- <div
                  class="circle mb-1"
                  style="background-color: var(--v-primary-base)"
                ></div> -->
                <div class="icon mb-1">
                  <v-icon
                    color="solucionado"
                    small
                  >
                    fa-robot
                  </v-icon>
                </div>
                <span>Solucionado <span class="conteo">({{ filterNumbers !== null ? filterNumbers.bot_resolve : 0 }})</span></span>
              </div>
            </v-btn>
            <v-btn
              depressed
              class="v-btn_tab"
              :class="{ 'active': activeFilter === 'all' }"
              @click="sendFilter('all')"
            >
              <div class="d-flex flex-column align-center">
                <div
                  class="circle mb-1"
                  style="background-color: var(--v-primary-base)"
                ></div>
                <span>Todos  <span class="conteo">({{ filterNumbers !== null ? filterNumbers.all : 0 }})</span></span>
              </div>
            </v-btn>
            <v-btn
              depressed
              class="v-btn_tab"
              :class="{ 'active': activeFilter === 'pending' }"
              @click="sendFilter('pending')"
            >
              <div class="d-flex flex-column align-center">
                <div
                  class="circle mb-1"
                  style="background-color: var(--v-pending-base)"
                ></div>
                <span>Pendiente <span class="conteo">({{ filterNumbers !== null ? filterNumbers.pending : 0 }})</span></span>
              </div>
            </v-btn>
            <v-btn
              depressed
              class="v-btn_tab"
              :class="{ 'active': activeFilter === 'reviewed' }"
              @click="sendFilter('reviewed')"
            >
              <div class="d-flex flex-column align-center">
                <div
                  class="circle mb-1"
                  style="background-color: var(--v-reviewed-base)"
                ></div>
                <span>Revisado <span class="conteo">({{ filterNumbers !== null ? filterNumbers.reviewed : 0 }})</span></span>
              </div>
            </v-btn>
            <v-btn
              depressed
              class="v-btn_tab"
              :class="{ 'active': activeFilter === 'passed' }"
              @click="sendFilter('passed')"
            >
              <div class="d-flex flex-column align-center">
                <div
                  class="circle mb-1"
                  style="background-color: var(--v-passed-base)"
                ></div>
                <span>Aprobado <span class="conteo">({{ filterNumbers !== null ? filterNumbers.passed : 0 }})</span></span>
              </div>
            </v-btn>
            <v-btn
              depressed
              class="v-btn_tab"
              :class="{ 'active': activeFilter === 'rejected' }"
              @click="sendFilter('rejected')"
            >
              <div class="d-flex flex-column align-center">
                <div
                  class="circle mb-1"
                  style="background-color: var(--v-rejected-base)"
                ></div>
                <span>Rechazado <span style="color: var(--v-grey-base)">({{ filterNumbers !== null ? filterNumbers.rejected : 0 }})</span></span>
              </div>
            </v-btn>
            <v-btn
              depressed
              class="v-btn_tab"
              :class="{ 'active': activeFilter === 'cancel' }"
              @click="sendFilter('canceled')"
            >
              <div class="d-flex flex-column align-center">
                <div
                  class="circle mb-1"
                  style="background-color: var(--v-canceled-base)"
                ></div>
                <span>Cancelado <span class="conteo">({{ filterNumbers !== null ? filterNumbers.canceled : 0 }})</span></span>
              </div>
            </v-btn>
          </div>
          <v-btn
            color="primary"
            elevation="0"
            @click="sheet = !sheet"
          >
            Filtrar más
          </v-btn>
          <v-divider
            v-if="getStates > 0"
            vertical
            class="ml-4"
          />
          <v-btn
            v-if="getStates > 0"
            outlined
            class="ml-4"
            @click="showChangeStates"
          >
            Cambiar estado <span class="ml-2 conteo">({{ getStates }})</span>
          </v-btn>
          <div
            v-if="getStates !== 0"
            class="p-0 m-0"
          >
            <xlsx-workbook>
              <xlsx-sheet
                :key="getSheets.name"
                :collection="getSheets.data"
                :sheet-name="getSheets.name"
              />
              <xlsx-download :filename="moment().format('YYYY-MM-DD') + '-EcobotReport.xlsx'">
                <v-btn
                  class="ml-4"
                  outlined
                >
                  Exportar
                  <v-icon
                    dark
                    right
                  >
                    fa-file-download
                  </v-icon>
                </v-btn>
              </xlsx-download>
            </xlsx-workbook>
          </div>
          <v-bottom-sheet v-model="sheet">
            <v-sheet
              class="text-center"
              height="auto"
            >
              <v-container fluid>
                <v-row
                  align="center"
                  class=""
                >
                  <v-col cols="12">
                    <h3 class="text-h4 font-weight-bold">
                      Filtrar más
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <DateSearch @selectedDates="getRecord($event)" />
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="search"
                      filled
                      label="Buscar palabra clave"
                      append-outer-icon="fas fa-search"
                      @click:append-outer="sendSearch"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-bottom-sheet>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import DateSearch from '@/components/DateSearch.vue';
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx/dist/vue-xlsx.es.js';
export default {
  name: 'PageTitle',
  components: {
    DateSearch, XlsxWorkbook, XlsxSheet, XlsxDownload,
  },
  props: {
    titleText: { type: String, default: '' },
    states: { type: Number, default: 0 },
    sheets: { type: Object, default: null },
    filterNumbers: { type: Object, default: null },
    activeFilter: { type: String, default: 'all' },
  },
  data: () => ({
    moment,
    sheet: false,
    date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    menu1: false,
    search: '',
    toggle_exclusive: [],
  }),
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : '';
    },
    computedDateFormattedDatefns() {
      return this.date ? format(parseISO(this.date), 'EEEE, MMMM do yyyy') : '';
    },
    getStates() {
      return this.states;
    },
    getSheets() {
      return this.sheets;
    },
  },
  methods: {
    sendSearch() {
      // console.log('Buscar......', this.search);
      this.$emit('sendSearch', this.search);
    },
    getRecord(dates) {
      // this.startDate = dates.startDate;
      // this.endDate = dates.endDate;
      this.$emit('sendReport', dates);
    },
    sendFilter(filter) {
      this.$emit('statusFilter', filter);
    },
    showChangeStates() {
      this.$emit('showChangeStates');
    },
  },
};
</script>
