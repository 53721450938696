<template>
  <div class="DateSearch">
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
      content-class="date-menu-style"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          :label="$t('commonDateSearch.inputLabel')"
          append-icon="fa-calendar-alt"
          readonly
          class="rounded-0"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="dates"
        :locale="locale"
        range
        no-title
        scrollable
        color="primary"
      />
      <v-row
        no-gutters
        style="background-color: var(--v-backgroundDashboard-base)"
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="dateMenu = false"
        >
          {{ $t('commonForm.cancel') }}
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="isButtonDisabled"
          @click="sendDates()"
        >
          {{ $t('commonForm.ok') }}
        </v-btn>
      </v-row>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'DateSearch',
  props: {
    daysOffSet: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      dateMenu: false,
      dates: null,
    };
  },
  computed: {
    locale: function() {
      return this.$i18n.locale;
    },
    dateRangeText: function() {
      if (this.dates !== null && this.dates.length > 1) {
        return this.dates.join(' ~ ');
      }
      return [];
    },
    isButtonDisabled: function() {
      return !(this.dates !== null && this.dates.length > 1);
    },
  },
  mounted() {
    this.initialDate();
    this.$nextTick(() => {
      this.sendDates();
    });
  },
  methods: {
    sendDates() {
      this.dateMenu = false;
      const sortedDates = this.dates.slice().sort((a, b) => new Date(a) - new Date(b));
      this.$emit('selectedDates', {
        startDate: String(sortedDates[0]) + 'T00:00:00',
        endDate: String(sortedDates[1]) + 'T23:00:00',
      });
    },
    initialDate() {
      const todayDate = new Date();
      const fromDate = new Date();
      const daysOffSet = this.daysOffSet <= 0 ? 15 : this.daysOffSet;
      // const firstDayMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      fromDate.setDate(todayDate.getDate() - daysOffSet);
      const formatToday = todayDate.toISOString().slice(0, 10);
      const formatFirstDay = fromDate.toISOString().slice(0, 10);
      this.dates = [formatFirstDay, formatToday];
    },
  },
};
</script>

<style lang="scss" scoped>
.date-menu-style {
  box-shadow: none;
  border-radius: 0px;
}
</style>
