<template>
  <div class="reports">
    <PageTitle
      title-text="Reportes"
      :states="selected.length"
      :sheets="sheets"
      :filter-numbers="filterNumbers"
      :active-filter="filterBtnActive"
      @sendReport="getRecord($event)"
      @statusFilter="filterByStatus"
      @sendSearch="filterBySearch"
      @showChangeStates="sheetStates = !sheetStates"
    />
    <div class="content">
      <v-container
        fluid
        class="pt-0 align-start"
        fill-height
      >
        <!-- ----------- {{ clickRow }} . {{ selected.length }} -->
        <v-row
          justify-md="space-between"
          class="flex-sm-row-reverse mt-0"
        >
          <v-col
            md="4"
            sm="5"
            cols="12"
            class="ticket_panel"
            :class="clickRow !== '' ? 'selected' : 'default'"
          >
            <div class="message_initial">
              <h5 class="mb-3">
                ¿Cómo hacerlo?
              </h5>
              <p>
                1) Para ver los detalles de un ticket, selecciona sobre alguna
                fila del ticket.
              </p>
              <p>
                2) Para exportar o cambiar de estado, en el lado izquierdo de la
                tabla, haz click para seleccionar algún ticket o todos como
                este:
                <v-checkbox
                  v-model="ex4"
                  label="ejemplo"
                  color="grey"
                  value="ejemplo"
                  hide-details
                />
              </p>
            </div>
            <div class="header">
              <h5 class="title_ticket">
                Ticket -
                <span class="number_ticket">
                  {{ firstSelect !== null ? firstSelect.id : '000000' }}</span>
              </h5>
            </div>
            <div class="divider"></div>
            <div class="content">
              <v-tabs
                v-model="tabs"
                centered
                height="35px"
              >
                <v-tab> Detalles </v-tab>
                <v-tab> Ajustes </v-tab>
                <v-tab> Historial </v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tabs"
                class="pb-12"
              >
                <v-tab-item>
                  <v-card flat>
                    <ul class="list_ticket box">
                      <li>
                        <span class="data-title">Motivo</span><span class="line-dashed"></span><span
                          class=""
                          :class="firstSelect !== null ? 'data' : 'sin-data'"
                        >{{
                          firstSelect !== null ? firstSelect.motivo : '---'
                        }}</span>
                      </li>
                      <!-- <li
                        :class="
                          firstSelect !== null
                            ? getClassStatusCedisPerformance(
                              firstSelect.cedisValid
                            )
                            : ''
                        "
                      >
                        <span class="data-title">CEDIS Valido</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'">
                          {{
                            firstSelect !== null
                              ? firstSelect.cedisValid ? 'Si' : 'No'
                              : '---'
                          }}
                        </span>
                      </li> -->
                      <!-- <li>
                        <span class="data-title">CEDIS Correcto</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'">
                          {{ firstSelect !== null ? firstSelect.cedis : '---' }}
                        </span>
                      </li> -->
                      <li>
                        <span class="data-title">Rendimiento histórico</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'">
                          {{
                            firstSelect !== null
                              ? firstSelect.awgRendimientoReal
                              : '---'
                          }}
                        </span>
                      </li>
                      <li
                        :class="
                          firstSelect !== null
                            ? getClassStatusCedisPerformance(
                              firstSelect.rendimientoValid
                            )
                            : ''
                        "
                      >
                        <span class="data-title">Rendimiento válido</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'">
                          {{
                            firstSelect !== null
                              ? firstSelect.rendimientoValid
                              : '---'
                          }}
                        </span>
                      </li>
                      <li>
                        <span class="data-title">Recorridos(RD)</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'">
                          {{
                            firstSelect !== null
                              ? firstSelect.awgKmRecorridos
                              : '---'
                          }}
                          <span> km</span></span>
                      </li>
                      <li
                        :class="
                          firstSelect !== null
                            ? alertKmPorRecorrer(firstSelect)
                            : ''
                        "
                      >
                        <span class="data-title">Recorrido Adicional(RA)</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'">
                          {{
                            firstSelect !== null
                              ? firstSelect.motivo.toLowerCase() === 'revision de saldo fijo' ? 0 : firstSelect.kmPorRecorrer
                              : 0
                          }}
                          <span> km</span></span>
                      </li>
                      <li>
                        <span class="data-title">Litros sugeridos</span>
                        <span class="line-dashed"></span><span :class="firstSelect !== null ? 'data' : 'sin-data'">{{
                          firstSelect !== null
                            ? firstSelect.awgLitrosSugeridos
                            : '---'
                        }}</span>
                      </li>
                      <!-- <li>
                        <span class="data-title">Tarjeta</span>
                        <span class="line-dashed"></span><span :class="firstSelect !== null ? 'data' : 'sin-data'">{{
                          firstSelect !== null
                            ? getFormatCard(firstSelect.tarjeta)
                            : '---'
                        }}</span>
                      </li> -->
                      <li
                        :class="
                          firstSelect !== null
                            ? Number(firstSelect.R2) > 0
                              ? 'status_passed'
                              : 'status_rejected'
                            : ''
                        "
                      >
                        <span class="data-title">Saldo sugerido(R2)</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'"><span>$</span>{{
                          firstSelect !== null ? firstSelect.R2 : '---'
                        }}</span>
                      </li>
                      <li
                        :class="
                          firstSelect !== null
                            ? Number(firstSelect.saldoEntregado) !== 0
                              ? 'status_passed'
                              : 'status_rejected'
                            : ''
                        "
                      >
                        <span class="data-title">Saldo entregado</span>
                        <span class="line-dashed"></span>
                        <span :class="firstSelect !== null ? 'data' : 'sin-data'"><span>$</span>{{
                          firstSelect !== null
                            ? firstSelect.saldoEntregado
                            : '---'
                        }}</span>
                      </li>
                    </ul>
                    <div class="divider mt-3"></div>
                    <div class="box">
                      <h3 class="text-h6 mb-3">
                        Historia Adicionales
                      </h3>
                      <p
                        class="mb-0"
                        :class="firstSelect !== null ? 'data' : 'sin-data'"
                      >
                        {{
                          firstSelect !== null
                            ? firstSelect.adicionales4LW
                            : '---'
                        }}
                      </p>
                    </div>
                    <div class="divider mt-3"></div>
                    <div class="box">
                      <h3 class="text-h6 mb-3">
                        Comentario de solución
                      </h3>
                      <p
                        class="mb-0"
                        :class="firstSelect !== null ? 'data' : 'sin-data'"
                      >
                        {{
                          firstSelect !== null
                            ? firstSelect.historySolution.length !== 0
                              ? firstSelect.historySolution[
                                firstSelect.historySolution.length - 1
                              ].respuesta
                              : 'No hay respuesta'
                            : '---'
                        }}
                      </p>
                    </div>
                    <div class="divider mt-3"></div>

                    <div class="box">
                      <h3 class="text-h6 mb-3">
                        Comentarios
                      </h3>
                      <p
                        class="mb-0"
                        :class="firstSelect !== null ? 'data' : 'sin-data'"
                      >
                        {{
                          firstSelect !== null ? firstSelect.comentario : '---'
                        }}
                      </p>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="box">
                      <p class="mb-0">
                        El usuario podrá consultar por el número de ticket el
                        monto que se le esta asignando a su tarjeta.
                      </p>
                    </v-card-text>
                    <!-- <v-list-item-group
                      v-if="!undoAprove"
                      v-model="settings"
                      multiple
                      active-class=""
                      class="box"
                    >
                      <v-list-item>
                        <template v-slot:default="">
                          <v-list-item-action>
                            <v-btn
                              x-small
                              color="secondary"
                              dark
                              @click="
                                moneyToDispatch =
                                  firstSelect !== null ? firstSelect.R2 : 0
                              "
                            >
                              Usar
                            </v-btn>
                          </v-list-item-action>
                          <div class="boxs">
                            <div class="box">
                              <span class="data-title">Km Recorridos</span>
                              <div class="line-dashed"></div>
                              <span
                                :class="
                                  firstSelect !== null ? 'data' : 'sin-data'
                                "
                              ><span>{{
                                 firstSelect !== null
                                   ? firstSelect.awgKmRecorridos
                                   : '---'
                               }}</span>
                                <span>km</span></span>
                            </div>
                            <div class="box">
                              <span class="data-title">Saldo sugerido</span>
                              <div class="line-dashed"></div>
                              <span
                                :class="
                                  firstSelect !== null ? 'data' : 'sin-data'
                                "
                              ><span>$</span>
                                <span>{{
                                  firstSelect !== null ? firstSelect.R2 : '---'
                                }}</span></span>
                            </div>
                          </div>
                        </template>
                      </v-list-item>
                      <v-list-item>
                        <template v-slot:default="">
                          <v-list-item-action>
                            <v-btn
                              x-small
                              color="secondary"
                              dark
                              @click="
                                moneyToDispatch =
                                  firstSelect !== null ? firstSelect.R3 : 0
                              "
                            >
                              Usar
                            </v-btn>
                          </v-list-item-action>
                          <div class="boxs">
                            <div class="box">
                              <span class="data-title">Km por Recorrer</span>
                              <div class="line-dashed"></div>
                              <span
                                :class="
                                  firstSelect !== null ? 'data' : 'sin-data'
                                "
                              ><span>{{
                                 firstSelect !== null
                                   ? firstSelect.kmPorRecorrer
                                   : '---'
                               }}</span>
                                <span>km</span></span>
                            </div>
                            <div class="box">
                              <span class="data-title">Saldo sugerido</span>
                              <div class="line-dashed"></div>
                              <span
                                :class="
                                  firstSelect !== null ? 'data' : 'sin-data'
                                "
                              ><span>$</span>
                                <span>{{
                                  firstSelect !== null ? firstSelect.R3 : '---'
                                }}</span></span>
                            </div>
                          </div>
                        </template>
                      </v-list-item>
                    </v-list-item-group> -->
                    <!-- <div class="divider my-6"></div> -->
                    <v-container
                      fluid
                      class="box"
                    >
                      <v-row
                        v-if="!undoAprove"
                        align="center"
                      >
                        <v-col cols="6">
                          <span>Monto a depositar</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="moneyToDispatch"
                            prefix="$"
                            type="number"
                            required
                            hide-details
                          />
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="6">
                          <span>Estado de solicitud</span>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            v-model="currentStatus"
                            :items="[
                              'Pendiente',
                              'Revisado',
                              'Aprobado',
                              'Rechazado',
                              'Cancelado',
                            ]"
                            label=""
                            outlined
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <div class="divider my-6"></div>
                    <div
                      v-if="currentStatus !== 'Aprobado'"
                      class="box"
                    >
                      <v-textarea
                        v-model="ticketSolution"
                        counter
                        label="Comentarios de notificaciones"
                        hint="Escribe una breve descripción por el cual se asigna
                        el nuevo estado. El comentario del estado revisado será enviado
                        al momento de aprobarlo."
                      />
                    </div>
                    <div class="text-center">
                      <v-btn
                        class="ma-2"
                        outlined
                        color="indigo"
                        @click="switchToDispatched"
                      >
                        Actualizar
                      </v-btn>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="box">
                      Verás el historial de cambios del ticket que ha presentado
                    </v-card-text>
                    <div
                      v-if="
                        firstSelect !== null &&
                          firstSelect.historySolution.length !== 0
                      "
                      class="timeline_box box"
                    >
                      <!--FIXME:  Israel, cada per_timeline se agrega una clase
                       diferente dependiendo de un estado (status_rejected,
                       status_passed, status_reviewed, status_canceled, status_pending) -->
                      <div
                        v-for="(history, index) in firstSelect.historySolution"
                        :key="firstSelect.id + '_' + index"
                        class="per_timeline status_rejected"
                        :class="'status_' + getColor(history.status)"
                      >
                        <p class="mb-0 time">
                          {{ getFormatDate(history.date) }}
                        </p>
                        <h6 v-if="history.respuesta !== ''">
                          Respuesta
                        </h6>
                        <p
                          v-if="history.respuesta !== ''"
                          class="answer mb-1"
                        >
                          {{ history.respuesta }}
                        </p>
                        <div class="estado">
                          <h6>Estado</h6>
                          <p class="ml-3 mb-0">
                            {{
                              history.status.charAt(0).toUpperCase() +
                                history.status.slice(1)
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="box"
                    >
                      <!-- FIXME: Citlalli: aqui debe de ir un diseño para cuando no existe ningun historial -->
                      El ticket aun no tiene un historial.
                    </div>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
          <v-col
            md="8"
            sm="7"
            cols="12"
            class="table_panel"
          >
            <div class="header d-flex align-center py-4">
              <v-row
                justify="start"
                class="pl-4"
              >
                <v-col cols="auto">
                  <p>Fechas por período: {{ startDate }} ~ {{ endDate }}</p>
                </v-col>
                <v-col cols="auto">
                  <v-divider vertical />
                </v-col>
                <v-col cols="auto">
                  <p>
                    Palabras filtradas:
                    {{ searchData !== '' ? searchData : 'No hay palabra' }}
                  </p>
                </v-col>
              </v-row>
            </div>
            <div class="content">
              <v-data-table
                v-model="selected"
                :single-select="singleSelect"
                :headers="headers"
                :items="reports"
                item-key="id"
                class="elevation-0"
                show-select
                @click:row="rowSelection"
              >
                <template v-slot:item="{ item, isSelected }">
                  <tr
                    :class="clickRow === item.id ? 'selected' : 'default'"
                    @click="rowSelection(item)"
                  >
                    <td>
                      <v-simple-checkbox
                        :ripple="false"
                        :value="isSelected"
                        @input="addItem(item)"
                      />
                    </td>
                    <td>{{ item.id }}</td>
                    <!-- <td>{{ item.name }}</td> -->
                    <td>{{ formatShowDate(item.createdAt) }}</td>
                    <td>{{ formatShowDate(item.updatedAt) }}</td>
                    <td>{{ item.gpid }}</td>
                    <td>{{ item.cedisOriginal }}</td>
                    <td>{{ item.economico }}</td>
                    <td>{{ item.supervisor }}</td>
                    <td>{{ item.region }}</td>
                    <td>
                      <div class="status">
                        {{ item.status }}
                        <v-chip :color="getColor(item.status)" />
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <v-bottom-sheet v-model="sheetStates">
              <v-sheet
                class="text-center"
                height="auto"
              >
                <v-container fluid>
                  <v-row align="center">
                    <v-col>
                      <h3 class="text-h4">
                        Cambiar estado
                      </h3>
                      <p style="color: var(--v-grey-base)">
                        {{ selected.length }} ticket(s) seleccionado(s)
                      </p>
                      <p class="">
                        Los respectivos usuarios recibierán una notificación con
                        la actualización del estado
                      </p>
                    </v-col>
                  </v-row>
                  <v-row
                    align="center"
                    class="flex-column"
                  >
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="currentStatusStates"
                        :items="[
                          'Pendiente',
                          'Revisado',
                          'Aprobado',
                          'Rechazado',
                          'Cancelado',
                        ]"
                        label="Estado"
                        outlined
                        hide-details
                      />
                    </v-col>
                    <v-col
                      v-if="currentStatusStates === 'Revisado'"
                      cols="12"
                      md="6"
                    >
                      <v-textarea
                        v-model="ticketSolutionStates"
                        counter
                        label="Comentarios de notificaciones"
                        hint="Escribe una breve descripción por el cual
                         se asigna el nuevo estado. El comentario en estado
                          revisado será enviado an momento de aprobarlo."
                      />
                    </v-col>
                    <v-col
                      v-else-if="currentStatusStates !== 'Aprobado'"
                      cols="12"
                      md="6"
                      class="list_ticket"
                    >
                      <p
                        style="color: var(--v-grey-darken3)"
                        class="status_reviewed d-flex align-center"
                      >
                        <span
                          style="color: var(--v-reviewed-base)"
                          class="data mr-3"
                        ></span>Se le notificará como último comentario del estado de
                        <span class="font-italic ml-3 font-weight-bold">revisado</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="end"
                    class="mt-12"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                    >
                      <v-btn
                        outlined="primary"
                        min-width="280px"
                        @click="sheetStates = false"
                      >
                        Cancelar
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="auto"
                    >
                      <v-btn
                        color="primary"
                        min-width="280px"
                        @click="bulkReport"
                      >
                        Actualizar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-bottom-sheet>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitleReport.vue';
import reportService from '../services/modules/reports';
import dateFormatMixin from '@/mixins/dateFormat';
import moment from 'moment';
const monthsSpanish = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
export default {
  components: { PageTitle },
  mixins: [dateFormatMixin],
  data() {
    return {
      ex4: ['checkedExample'],
      tabs: null,
      value: 'Hello!',
      singleSelect: false,
      selected: [],
      settings: [],
      daysOffSet: 15,
      startDate: '',
      endDate: '',
      moneyToDispatch: 0,
      currentStatus: '',
      ticketSolution: '',
      ticketSolutionStates: '',
      headers: [
      //   {
      //     text: 'Nombre',
      //     align: 'start',
      //     sortable: false,
      //     value: 'name',
      //   },
        { text: 'Ticket', value: 'id', align: 'center' },
        { text: 'Creado', value: 'createdAt', align: 'center' },
        { text: 'Actualizado', value: 'updatedAt', align: 'center' },
        { text: 'GPID', value: 'gpid', align: 'center' },
        { text: 'CEDIS', value: 'cedisOriginal', align: 'center' },
        {
          text: 'Económico', value: 'economico', align: 'center', width: 120,
        },
        { text: 'Supervisor', value: 'supervisor', align: 'center' },
        { text: 'Región', value: 'region', align: 'center' },
        { text: 'Proceso', value: 'status', align: 'center' },
      ],
      reports: [],
      allReports: [],
      firstSelect: null,
      undoAprove: false,
      sheetStates: false,
      currentStatusStates: '',
      searchData: '',
      clickRow: '',
      filterNumbers: null,
      filterBtnActive: 'all',
    };
  },
  computed: {
    sheets() {
      return {
        name: 'EcobotinReport',
        data: this.selected.map((item) => {
          return {
            Fecha: this.formatShowDate(item.createdAt),
            Económico: item.economico,
            // Región: item.region,
            // '# de tarjeta': item.tarjeta,
            'KM (RA)': Number(item.kmPorRecorrer).toFixed(0),
            'Monto sugerido': Number(item.R3).toFixed(0),
            'Monto entregado': Number(item.saldoEntregado).toFixed(0),
            Motivos: item.motivo,
            Comentario: item.comentario,
            'Atendido por Ecobot': item.extras && item.extras.dispatchByBot ? 'Si' : 'No',
          };
        }),
      };
    },
  },
  mounted() {
    this.initialDate();
  },
  methods: {
    getColor(status) {
      if (status === 'pendiente') return 'pending';
      else if (status === 'revisado') return 'reviewed';
      else if (status === 'aprobado') return 'passed';
      else if (status === 'rechazado') return 'rejected';
      else if (status === 'cancelado') return 'canceled';
      else return 'primary';
    },
    getFormatCard(card) {
      const resultString =
        card.slice(0, 4) +
        ' ' +
        card.slice(4, 8) +
        ' ' +
        card.slice(8, 12) +
        ' ' +
        card.slice(12, card.length);
      return resultString;
    },
    getFormatDate(date) {
      const momentDate = moment(date);
      const month = monthsSpanish[momentDate.month()];
      const finalDateString =
        month +
        ' ' +
        momentDate.date() +
        ' ' +
        momentDate.year() +
        ' ' +
        momentDate.format('LT');
      return finalDateString;
    },
    initialDate() {
      const todayDate = new Date();
      const fromDate = new Date();
      const daysOffSet = this.daysOffSet <= 0 ? 15 : this.daysOffSet;
      // const firstDayMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      fromDate.setDate(todayDate.getDate() - daysOffSet);
      this.startDate = fromDate.toISOString().slice(0, 10);
      this.endDate = todayDate.toISOString().slice(0, 10);
      const formatToday = todayDate.toISOString().slice(0, 10) + 'T23:00:00';
      const formatFirstDay = fromDate.toISOString().slice(0, 10) + 'T00:00:00';
      this.getReport(formatFirstDay, formatToday);
    },
    async bulkReport() {
      // const resConfirm = await this.$confirm(`Procederás a aprobar ${this.selectedItems.length} tickets .\
      // <br>¿deseas continuar?`);
      // if (!resConfirm) {
      //   return;
      // }
      const status = this.currentStatusStates.toLowerCase();
      const statusItems = this.selected.filter(
          (item) => item.status === status,
      );
      if (this.currentStatusStates === '') {
        this.$vToastify.warning('Es requerido seleccionar un estado.');
        return;
      }
      if (statusItems.length > 0) {
        this.$vToastify.warning(
            'El estado debe ser diferente en: ' +
          statusItems.map((item) => item.ticket).join(', '),
        );
        return;
      }
      if (this.ticketSolutionStates === '' && status === 'revisado') {
        this.$vToastify.warning(
            'Es requerido un comentario para el estado ' + this.currentStatus,
        );
        return;
      }

      const bulkData = this.selected.map((item) => {
        return { _id: item._id, saldoEntregado: item.saldoEntregado };
      });
      reportService
          .updateBulkReport({
            bulkData,
            status,
            ticketSolucion: this.ticketSolutionStates,
          })
          .then(() => {
            this.selected = [];
            this.ticketSolutionStates = '';
            console.log('SUCCESS!!');
            this.getReport(
                this.startDate + 'T00:00:00',
                this.endDate + 'T23:00:00',
            );
            this.sheetStates = false;
            this.$vToastify.success('Se han actualizado los Tickets');
          })
          .catch((error) => {
            console.log('FAILURE!!', error);
            this.selected = [];
            this.ticketSolutionStates = '';
            this.sheetStates = false;
            this.$vToastify.error(
                'Ocurrio un error en el servidor:' + error.message,
            );
          });
    },
    addItem(row) {
      const indexFound = this.selected.findIndex((element) => {
        return element.id === row.id;
      });
      if (indexFound !== -1) {
        this.selected.splice(indexFound, 1);
      } else {
        this.selected.push(row);
      }
    },
    rowSelection(row) {
      this.clickRow = row.id;
      this.firstSelect = row;
      if (this.firstSelect.status === 'pendiente') {
        this.moneyToDispatch = this.firstSelect.saldoEntregado;
        this.undoAprove = false;
      } else {
        this.undoAprove = true;
      }
      this.ticketSolution = '';
      this.currentStatus =
        this.firstSelect.status.charAt(0).toUpperCase() +
        this.firstSelect.status.slice(1);
      // const indexFound = this.selected.findIndex((element) => {
      //   return element.id === row.id;
      // });
      // if (indexFound !== -1) {
      //   this.selected.splice(indexFound, 1);
      //   if (this.selected.length === 0) {
      //     this.firstSelect = null;
      //     this.moneyToDispatch = 0;
      //     this.currentStatus = '';
      //     this.ticketSolution = '';
      //   }
      //   if (this.selected.length === 1) {
      //     if (this.selected[0].status === 'pendiente') {
      //       this.moneyToDispatch = this.selected[0].saldoEntregado;
      //       this.undoAprove = false;
      //     } else {
      //       this.undoAprove = true;
      //     }
      //     this.ticketSolution = '';
      //     this.firstSelect = this.selected[0];
      //     this.currentStatus = this.selected[0].status.charAt(0).toUpperCase() + this.selected[0].status.slice(1);
      //   }
      // } else {
      //   this.selected.push(row);
      //   if (this.selected.length === 1) {
      //     this.firstSelect = row;
      //     if (this.firstSelect.status === 'pendiente') {
      //       this.moneyToDispatch = this.firstSelect.saldoEntregado;
      //       this.undoAprove = false;
      //     } else {
      //       this.undoAprove = true;
      //     }
      //     this.ticketSolution = '';
      //     this.currentStatus = this.firstSelect.status.charAt(0).toUpperCase() + this.firstSelect.status.slice(1);
      //   }
      // }
    },
    getClassStatusCedisPerformance(data) {
      if (!data || (typeof data === 'string' && data.toLowerCase() !== 'true')) { return 'status_rejected'; }
      return 'status_passed';
    },
    alertKmPorRecorrer(item) {
      const thr = Number(item.awgKmRecorridos) * 0.6;
      if (thr >= item.kmPorRecorrer) return 'status_passed';
      return 'status_rejected';
    },
    filterByStatus(status) {
      this.selected = [];
      switch (status) {
        case 'pending':
          this.filterBtnActive = 'pending';
          this.reports = this.allReports.filter(
              (element) => element.status === 'pendiente',
          );
          break;
        case 'passed':
          this.filterBtnActive = 'passed';
          this.reports = this.allReports.filter(
              (element) => element.status === 'aprobado',
          );
          break;
        case 'reviewed':
          this.filterBtnActive = 'reviewed';
          this.reports = this.allReports.filter(
              (element) => element.status === 'revisado',
          );
          break;
        case 'rejected':
          this.filterBtnActive = 'rejected';
          this.reports = this.allReports.filter(
              (element) => element.status === 'rechazado',
          );
          break;
        case 'canceled':
          this.filterBtnActive = 'canceled';
          this.reports = this.allReports.filter(
              (element) => element.status === 'cancelado',
          );
          break;
        case 'bot':
          this.filterBtnActive = 'bot_resolve';
          this.reports = this.allReports.filter((element) => {
            if (element.extras && element.extras.dispatchByBot) {
              return true;
            }
          });
          break;
        default:
          this.filterBtnActive = 'all';
          this.reports = this.allReports;
          break;
      }
      this.isFirstSelectOnViewedReports();
    },
    isFirstSelectOnViewedReports() {
      if (this.firstSelect !== null) {
        const exist = this.reports.some((element) => {
          return element.id === this.firstSelect.id;
        });
        if (!exist) {
          this.firstSelect = null;
          this.clickRow = '';
        }
      }
    },
    filterBySearch(search) {
      if (search !== null) {
        // Filters for every column
        const filterName = this.allReports.filter((element) => {
          return element.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        });

        const filterMotivo = this.allReports.filter((element) => {
          return element.motivo.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        });

        const filterCedisOriginal = this.allReports.filter((element) => {
          return (
            element.cedisOriginal.toLowerCase().indexOf(search.toLowerCase()) >=
            0
          );
        });

        const filterEconomico = this.allReports.filter((element) => {
          return (
            element.economico.toLowerCase().indexOf(search.toLowerCase()) >= 0
          );
        });

        const filtergpid = this.allReports.filter((element) => {
          return element.gpid.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        });

        const filterTicket = this.allReports.filter((element) => {
          return element.id.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        });

        // put all together
        const combinedArray = [
          ...filterName,
          ...filterMotivo,
          ...filterCedisOriginal,
          ...filterEconomico,
          ...filtergpid,
          ...filterTicket,
        ];

        // Delete duplicate values
        const cleanCombinedArray = combinedArray.reduce((acc, current) => {
          const ele = acc.find((item) => item.id === current.id);
          if (!ele) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.reports = cleanCombinedArray;
        this.searchData = search;
      } else {
        this.reports = this.allReports;
        this.searchData = '';
      }
    },
    fillFilterNumbers(reports) {
      const filterNumbersAux = {
        all: reports.length,
        pending: 0,
        reviewed: 0,
        passed: 0,
        rejected: 0,
        canceled: 0,
        bot_resolve: 0,
      };
      reports.forEach((element) => {
        switch (element.status) {
          case 'aprobado':
            filterNumbersAux.passed = filterNumbersAux.passed + 1;
            break;
          case 'revisado':
            filterNumbersAux.reviewed = filterNumbersAux.reviewed + 1;
            break;
          case 'pendiente':
            filterNumbersAux.pending = filterNumbersAux.pending + 1;
            break;
          case 'rechazado':
            filterNumbersAux.rejected = filterNumbersAux.rejected + 1;
            break;
          case 'cancelado':
            filterNumbersAux.canceled = filterNumbersAux.canceled + 1;
            break;
        }
        if (element.extras && element.extras.dispatchByBot) {
          filterNumbersAux.bot_resolve = filterNumbersAux.bot_resolve + 1;
        }
      });
      this.filterNumbers = filterNumbersAux;
    },
    async switchToDispatched() {
      // const resConfirm = await this.$confirm('Procederás a marcarlo como APROBADO.<br>¿deseas continuar?');
      if (!this.firstSelect.ticket) {
        return;
      }
      const item = this.firstSelect;
      const status = this.currentStatus.toLowerCase();
      if (status === item.status) {
        this.$vToastify.warning('El estado debe ser actualizado.');
        return;
      }
      if (
        this.ticketSolution === '' &&
        [
          'cancelado', 'rechazado', 'pendiente', 'revisado',
        ].includes(status)
      ) {
        this.$vToastify.warning(
            'Es requerido un comentario para el estado ' + this.currentStatus,
        );
        return;
      }
      const data = { status };
      if (!this.undoAprove) {
        data.saldoEntregado = Number(this.moneyToDispatch);
      }
      if (this.ticketSolution !== '') {
        data.ticketSolucion = this.ticketSolution;
      }
      reportService
          .updateReport({ _id: item._id, data })
          .then(() => {
            console.log('SUCCESS!!');
            this.getReport(
                this.startDate + 'T00:00:00',
                this.endDate + 'T23:00:00',
            );
            this.currentItem = {};
            this.moneyToDispatch = 0;
            this.currentStatus = '';
            this.ticketSolution = '';
            this.undoAprove = false;
            this.firstSelect = null;
            this.clickRow = '';
            this.$vToastify.success('Notificación enviada.');
          })
          .catch((error) => {
            console.log('FAILURE!!', error);
            this.undoAprove = false;
            this.currentItem = {};
            this.currentStatus = '';
            this.ticketSolution = '';
            this.moneyToDispatch = 0;
            this.firstSelect = null;
            this.clickRow = '';
            this.$vToastify.error(
                'Ocurrio un error en el servidor:' + error.message,
            );
          });
    },
    getRecord({ startDate, endDate }) {
      // this.startDate = startDate;
      // this.endDate = endDate;
      this.getReport(startDate, endDate);
    },
    getReport(startDate, endDate) {
      // this.requestReport = true;
      reportService
          .getReport(startDate, endDate)
          .then((response) => {
          // this.requestReport = false;
          // console.log(response.data);
            const auxReports = response.data;
            this.$vToastify.info(
                'Reportes de adicionales obtenidos exitosamente.',
            );
            auxReports.forEach((element) => {
              // element.createdAt = this.formatShowDate(element.createdAt);
              // element.updatedAt = this.formatShowDate(element.updatedAt);
              if (
                element.extras &&
              element.extras.calcR3 &&
              element.extras.calcR3.active
              ) {
                element.CA = element.extras.calcR3.CA || 1.05;
                element.PC = element.extras.calcR3.PC;
                element.RS = element.extras.calcR3.RS;
                element.CRA = element.extras.calcR3.CRA;
                element.V1 = element.extras.calcR3.V1;
                element.R1 = element.extras.calcR3.R1;
                element.R2 = element.extras.calcR3.R2;
                element.R3 = element.extras.calcR3.R3;
              } else {
                if (
                  element.extras &&
                element.extras.calcMejorRendimiento &&
                element.extras.calcMejorRendimiento.active
                ) {
                  element.R2 = element.saldoEntregado;
                  element.R3 = element.saldoEntregado;
                }
              }
            });
            this.fillFilterNumbers(auxReports);
            this.reports = auxReports;
            this.allReports = auxReports;
            this.filterByStatus('pending');
          // this.reports.forEach((element) => {
          //   const SF = Number(element.importePagado) || 0;
          //   const RD = Number(element.awgKmRecorridos);
          //   const RH = Number(element.awgRendimientoReal);
          //   const RA = Number(element.kmPorRecorrer);
          //   element.CA = 1.05;
          //   element.PC = (Number(element.awgSaldoSugerido)---Number(element.awgLitrosSugeridos)).toFixed(2);
          //   element.RS = Number(SF---4).toFixed(2);
          //   element.CRA = Number((RA---RH) * element.CA * Number(element.PC)).toFixed(2);
          //   element.V1 = Number((RD---RH) * element.CA * Number(element.PC)).toFixed(2);
          //   element.R1 = (Number(SF) - element.V1).toFixed(2);
          //   // R2=if(R1<0,CRA+R1,CRA-R1)
          //   if (element.R1 < 0) {
          //     element.R2 = (Number(element.CRA) + Number(element.R1)).toFixed(2);
          //   } else {
          //     element.R2 = (Number(element.CRA) - Number(element.R1)).toFixed(2);
          //   }
          //   element.R3 = (Number(element.R2) + Number( element.RS)).toFixed(2);
          // });
          })
          .catch((error) => {
            this.requestReport = false;
            console.error('FAILURE!!', error);
          });
    },
  },
};
</script>
